import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/acceso',
    name: 'access',
    component: () => import('../views/AccesoView.vue')
  },
  {
    path: '/registro',
    name: 'register',
    component: () => import('../views/RegisterView.vue')
  },
  {
    path: '/Paquetes',
    name: 'packages',
    component: () => import('../views/PackagesView.vue')
  },
  {
    path: '/NuevoEvento',
    name: 'newEvent',
    component: () => import('../views/NewEventView.vue')
  },
  {
    path: '/Boda/:codigo/:invitado?/:cantidad?',
    name: 'wedding',
    component: () => import('../views/WeddingView.vue')
  },
  {
    path: '/Invitaciones/:codigo/:invitado?/:cantidad?',
    name: 'invitation',
    component: () => import('../views/InvitationsView.vue')
  },
  {
    path: '/EditarEvento/:Id',
    name: 'editEvent',
    component: () => import('../views/EditEventView.vue')
  },
  {
    path: '/Reservaciones/:Id',
    name: 'reservations',
    component: () => import('../views/ConfirmationView.vue')
  },
  {
    path: '/Cupones',
    name: 'coupons',
    component: () => import('../views/CouponsView.vue')
  },
  {
    path: '/Test',
    name: 'test',
    component: () => import('../views/TestView.vue')
  },
  {
    path: '/Evento',
    name: 'event',
    component: () => import('../views/EventView.vue'),
    props: route => ({
      codigo: route.query.codigo,
      invitado: route.query.invitado,
      cantidad: route.query.cantidad,
      id: route.query.id
    }),
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'hash',
  routes
})

export default router
